<template>
  <v-container>
    <Logo class="logo" />
    <v-form
     @submit.prevent="submit"
      ref="form"
      lazy-validation
      v-if="true"
    >
      <v-row justify="center" v-if="validToken && !message">
        <v-col class="col-form pb-12 pt-12" md="6" cols="10">
          <v-row justify="center">
            <v-col md="8" cols="10" class="title-text">Digite a nova senha de 6 dígitos da sua conta digital Finago:</v-col>
            <v-col md="8" cols="10" class="pb-0">
              <v-text-field
                class="password"
                :append-icon="showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                v-model="password"
                :rules="passwordRules"
                v-mask="'######'"
                label="Nova senha"
              ></v-text-field>
            </v-col>
            <v-col md="8" cols="10" class="pt-0">
              <v-text-field
                class="confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                v-model="confirmPassword"
                :rules="passwordConfirmationRules"
                v-mask="'######'"
                label="Confirmar nova senha"
              ></v-text-field>
            </v-col>
            <v-col md="8" cols="10" class="button pt-12 pt-md-6">
              <v-btn class="btn-reset"
                rounded large block outlined color="#D70472" @click="validate">Alterar senha</v-btn>
            </v-col>
            <v-col md="8" cols="12" class="col-cancel pt-0">
              <a @click="back" class="cancel">Cancelar alteração</a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    <v-row justify="center" v-if="this.message">
        <v-col class="col-form pb-12 pt-12" md="6" cols="10">
          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-1 mt-4" src="@/assets/successPink.png" />
              </v-row>
            </v-col>
            <v-col md="8" cols="12" class="title-text pb-8">Senha alterada com sucesso!</v-col>
            <v-col md="8" cols="12" class="button">
              <v-btn class="btn-reset"
                rounded large block outlined color="#D70472" @click="back">Acessar Portal Finago</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <div class="text" v-if="!validToken">
        <p>O seu link de recuperação de senha já expirou.
        </p>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import Logo from '../../../components/Logo.vue';

export default {
  directives: {
    mask,
  },
  components: {
    Logo,
  },
  data() {
    return {
      showConfirmPassword: false,
      showNewPassword: false,
      password: '',
      confirmPassword: '',
      passwordConfirmationRules: [
        valueInput => !!valueInput || 'Senha de confirmação obrigatória',
        () => this.password === this.confirmPassword
          || 'Senhas devem ser iguais',
      ],
      passwordRules: [
        valueInput => !!valueInput || 'Senha obrigatória',
        valueInput => (valueInput && valueInput.length === 6)
          || 'Senha deve ter 6 dígitos',
        valueInput => (valueInput && valueInput.match(/^\d+$/))
          || 'Senha deve ter somente números',
        valueInput => (valueInput && valueInput.match(/^(?!.*?(\w)\1{5}).*$/gm))
          || 'Senha deve ter números diferentes',
        valueInput => (valueInput && ('0123456789012345789').indexOf(valueInput) === -1)
          || 'Senha deve ter números não sequenciais',
      ],
    };
  },
  methods: {
    ...mapActions(
      {
        validateTokenPasswordRecover: 'DigitalAccount/validateTokenPasswordRecover',
        resetPassword: 'DigitalAccount/resetPassword',
      },
    ),
    validate() {
      if (this.$refs.form.validate()) {
        this.resetPassword({
          password: this.password,
          token: this.queryToken,
        });
      }
    },
    redirect() {
      this.$router.push({ name: 'forgotPassword' });
    },
    back() {
      this.$router.push({ name: 'login' });
    },
  },
  computed: {
    ...mapGetters(
      {
        validToken: 'DigitalAccount/getValidToken',
        message: 'DigitalAccount/getResetPassword',
      },
    ),
    queryToken() {
      return this.$route.query.token;
    },
  },
  async created() {
    await this.validateTokenPasswordRecover(this.queryToken);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/commons/resetPassword.scss';
</style>
